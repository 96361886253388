import React, { FC } from 'react';
import { Link } from 'gatsby';

import Button from 'common/Button';
import GatsbyImage from 'common/GatsbyImage';
import ReviewStars from 'components/ReviewStars';

import './ProductItem.scss';

import { IProductItem } from './model';

const ProductItem: FC<IProductItem> = ({
  title,
  productURL,
  image,
  amountCondoms,
  buttonText,
  buttonAriaLabel,
  sku,
}) => (
  <Link to={productURL} className="product-item" aria-label={buttonAriaLabel || buttonText}>
    {amountCondoms ? <div className="product-item__pack">{amountCondoms}</div> : null}
    <GatsbyImage
      className="product-item__image"
      isLazyLoading
      fluid={image?.[0].localImage?.childImageSharp.fluid}
      alt={image?.[0].properties?.imageAltLabel}
    />
    <h3 className="product-item__title">{title}</h3>
    <div className="product-item__bottom">
      <ReviewStars sku={sku} showReview={false} className="product-item__reviews" />
      <Button
        link={productURL}
        btnColorVariant="dark"
        classes="product-item__button"
        ariaLabel={buttonAriaLabel || buttonText}
      >
        {buttonText.toUpperCase()}
      </Button>
    </div>
  </Link>
);

export default ProductItem;
